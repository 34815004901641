export class CsChannel {

  init(ac_consumer, stats_util_class_ref, menu_bar_class_ref) {    
    this.ac_consumer = ac_consumer;
    this.MenuBar = menu_bar_class_ref;
    this.StatsUtil = stats_util_class_ref;

    this.subscribeToCsControlChannel();
  };

  static broadcastMessage(hash) {
    cs_channel.ac_consumer.subscriptions.subscriptions[0].broadcastMessage(hash);
  };

  subscribeToCsControlChannel() {
    cs_channel.ac_consumer.subscriptions.create("CsControlChannel", {
      connected() {},
      disconnected() {},
      received(data) {
        // Called when there's incoming data on the websocket for this channel
        switch(data['type']) {
          case 'Notification-Clear':
            if (cs_channel.StatsUtil.isDetailedViewLoaded()) {
              $(html_ref.detailed.notif_text_id).text('');
              $(html_ref.detailed.notif_user_time_id).text('');
            } else if (cs_channel.StatsUtil.isMainViewLoaded()) {
              $(html_ref.main.notif_holder_id).hide();
              cs_channel.StatsUtil.lightenMainViewStats();
            }
            break;
          case 'Notification-New':
            if (cs_channel.StatsUtil.isDetailedViewLoaded()) {
              $(html_ref.detailed.notif_text_id).html(data['text']);
              $(html_ref.detailed.notif_user_time_id).text(data['username'] + ' | ' + data['time']);
            } else if (cs_channel.StatsUtil.isMainViewLoaded()) {
              $(html_ref.main.notif_text_id).html(data['text']);
              $(html_ref.main.notif_user_time_id).text(data['username'] + ' | ' + data['time']);
              cs_channel.StatsUtil.darkenMainViewStats();
              $(html_ref.main.notif_holder_id).show().addClass('pop-animation');
            }
            break;
          case 'TalentCue-Clear':
            if (cs_channel.StatsUtil.isMainViewLoaded()) {
              $(html_ref.main.talent_cue_holder_id).hide();
            }
            break;
          case 'TalentCue-New':
            if (cs_channel.StatsUtil.isMainViewLoaded()) {
              $(html_ref.main.talent_cue_holder_id + ' .' + html_ref.main.talent_cue_block_class).html(data['text']);
              $(html_ref.main.talent_cue_holder_id).show().addClass('pop-animation');
            }
            break;
          case 'SettingsDataSource-Change':
            if ($(html_ref.sidebar.sidebar_id).data('user-role') == 'client') {
              cs_channel.StatsUtil.clearCurrentView();
              $(html_ref.sidebar.sidebar_id).data('api-source', data['source']).attr('data-api-source', data['source']);
              $(html_ref.sidebar.sidebar_data_source_id).val(data['source']);
            }
            break;
          case 'SettingsFightAdvancementMode-Change':
            if ($(html_ref.sidebar.sidebar_id).data('user-role') == 'client') {
              $(html_ref.sidebar.sidebar_id).data('fight-advancement-mode', data['mode']).attr('data-fight-advancement-mode', data['mode']);
            }
            break;
          case 'AdminFight-Update':
            if (($(html_ref.sidebar.sidebar_id).data('user-role') == 'client') && ($(html_ref.sidebar.sidebar_id).data('fight-advancement-mode') == 'slave')
                && ($(html_ref.sidebar.sidebar_automatic_fight_advancement_id).val() == 'on')) {
              // Clear notification if any
              setTimeout(cs_channel.StatsUtil.clearNotification, 4000);
              // Load fight
              if ($(html_ref.shared.current_view_id).data('picked-event-id') != data['event_id']) {
                cs_channel.MenuBar.loadEventFightList(data['event_id'], function(){
                  cs_channel.MenuBar.loadFight(data['event_id'], data['fight_id']);
                });
              } else {
                cs_channel.MenuBar.loadFight(data['event_id'], data['fight_id']);
              }
            }
            break;
        }
      },
      broadcastMessage: function(data) {
        this.perform('broadcast_from_client', data);
      }
    });
  };

}

export let cs_channel = new CsChannel();