class AutoLogin {

  init() {
    const url_params = new URLSearchParams(window.location.search);

    const user = url_params.get('u');
    const passwd = url_params.get('p');

    if (user && passwd) {
      $('#user_username').val(user);
      $('#user_password').val(passwd);

      $('#new_user').submit();
    }
  };

}

export let auto_login = new AutoLogin();