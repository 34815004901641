export class Main {

  init(stats_util_class_ref) {
    this.fight_stats = null;
    this.fight_stat_mappings = null;

    this.StatsUtil = stats_util_class_ref;
  };

  static renderMain() {
    $(html_ref.shared.current_view_id).data('current-view', 'main');
    $(html_ref.shared.current_view_id).addClass('main-body');
    $(html_ref.shared.body_inner_id).addClass('main-parent');
    $('.' + html_ref.shared.view_btn_class).removeClass('active-font');
    $(html_ref.shared.main_btn_id).addClass('active-font');
    $(html_ref.shared.current_view_id).load('/screens/render_main', {}, function(response, status, xhr) {
      if (status == 'error') {
        toastr.error('Unable to load main view.');
      } else {
        $(html_ref.shared.current_view_id).removeClass('detailed-body history-body insights-body');
        $(html_ref.shared.body_inner_id).removeClass('detailed-parent history-parent insights-parent');
        main.fight_stats = null;
        main.fight_stat_mappings = main.StatsUtil.statMappings('main');
        main.StatsUtil.clearMainView();
        main.StatsUtil.loadFighterNames();
        main.StatsUtil.loadWCAbbr();
        main.StatsUtil.getFightMatchupStats(true, main.injectMatchupStats);
      }
    });
  };

  static injectFightStats(data) {
    if (main.fight_stat_mappings && main.StatsUtil.isMainViewLoaded() && ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(data['FightId'])) && ($(html_ref.shared.current_view_id).data('picked-round') == String(data['Round'])) && ($('#sidebar').data('api-source') == data['Source'])) {
      // Insert official result if available
      main.StatsUtil.insertFightStatus(data, true);

      for (const [key, val] of Object.entries(main.fight_stat_mappings)) {
        $('.red-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Red']) + val['unit']);
        $('.blue-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Blue']) + val['unit']);

        // Official total strike differential
        if (key == 'tsl') {
          if (data['Official']) {
            if (data[val['data'][0]][val['data'][1]]['Red'] > data[val['data'][0]][val['data'][1]]['Blue']) {
              $('.strike-diff-red').text('+' + String(data[val['data'][0]][val['data'][1]]['Red'] - data[val['data'][0]][val['data'][1]]['Blue']));
              $('.strike-diff-blue').text('');
            } else if (data[val['data'][0]][val['data'][1]]['Blue'] > data[val['data'][0]][val['data'][1]]['Red']) {
              $('.strike-diff-blue').text('+' + String(data[val['data'][0]][val['data'][1]]['Blue'] - data[val['data'][0]][val['data'][1]]['Red']));
              $('.strike-diff-red').text('');
            } else {
              $('.strike-diff-red').text('');
              $('.strike-diff-blue').text('');
            }
          } else {
            $('.strike-diff-red').text('');
            $('.strike-diff-blue').text('');
          }
        }
      }

      // Show
      main.StatsUtil.showMainViewStats();

      main.fight_stats = data;
    }
  };

  static injectFightOdds(data) {
    if (main.StatsUtil.isMainViewLoaded() && ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(data['FightId']))) {
      $('.red-live-dk-closing-odds').text((data['Odds']['Closing']['Red'] > 0) ? ('+' + String(data['Odds']['Closing']['Red'])) : data['Odds']['Closing']['Red']);
      $('.red-live-dk-live-odds').text((data['Odds']['Live']['Red'] > 0) ? ('+' + String(data['Odds']['Live']['Red'])) : data['Odds']['Live']['Red']);
      $('.blue-live-dk-closing-odds').text((data['Odds']['Closing']['Blue'] > 0) ? ('+' + String(data['Odds']['Closing']['Blue'])) : data['Odds']['Closing']['Blue']);
      $('.blue-live-dk-live-odds').text((data['Odds']['Live']['Blue'] > 0) ? ('+' + String(data['Odds']['Live']['Blue'])) : data['Odds']['Live']['Blue']);
    }
  };

  injectMatchupStats(data) {
    if (main.StatsUtil.isMainViewLoaded() && ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(data['FightId']))) {
      // Show
      main.StatsUtil.showMainViewMatchupStats();

      for (const [key, val] of Object.entries(main.StatsUtil.statMappings('matchup'))) {
        if (data[val['data'][0]][val['data'][1]]['Red'] !== '') {
          $('.red-matchup-avg-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Red']) + val['unit']);
          if (data[val['data'][0]][val['data'][1]]['RedHighlight']) {
            $('.red-matchup-avg-' + String(key)).addClass('yellow-font');
          } else {
            $('.red-matchup-avg-' + String(key)).removeClass('yellow-font');
          }
        } else {
          $('.red-matchup-avg-' + String(key)).text('');
        }
        if (data[val['data'][0]][val['data'][1]]['Blue'] !== '') {
          $('.blue-matchup-avg-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Blue']) + val['unit']);
          if (data[val['data'][0]][val['data'][1]]['BlueHighlight']) {
            $('.blue-matchup-avg-' + String(key)).addClass('yellow-font');
          } else {
            $('.blue-matchup-avg-' + String(key)).removeClass('yellow-font');
          }
        } else {
          $('.blue-matchup-avg-' + String(key)).text('');
        }
        if (data[val['data'][0]][val['data'][1]]['WC'] !== '') {
          $('.wc-matchup-avg-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['WC']) + val['unit']);
        } else {
          $('.wc-matchup-avg-' + String(key)).text('');
        }
        if (data[val['data'][0]][val['data'][1]]['UFC'] !== '') {
          $('.ufc-matchup-avg-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['UFC']) + val['unit']);
        } else {
          $('.ufc-matchup-avg-' + String(key)).text('');
        }
      }
    }
  };

}

export let main = new Main();