import { StatsUtil } from '../util/stats_util';
import { main } from './main';
import { detailed } from './detailed';
import { history } from './history';
import { insights } from './insights';
import { talent_cues } from './talent_cues';

if (StatsUtil.inStatsView()) {
  main.init(StatsUtil);
  detailed.init(StatsUtil);
  history.init(StatsUtil);
  insights.init(StatsUtil);
} else if (StatsUtil.inTalentCuesView()) {
  talent_cues.init();
}