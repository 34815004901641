export class Insights {

  init(stats_util_class_ref) {
    this.processed_insight_ids = [];
    this.indexed_insights = {};
    this.skip_inject_cycle = false;

    this.StatsUtil = stats_util_class_ref;

    $(html_ref.shared.current_view_id).on('change', '.insights-sort-by-rb', this.controlsOnChange);
    $(html_ref.shared.current_view_id).on('change', '.insights-corner-filter-cb', this.controlsOnChange);
    $(html_ref.shared.current_view_id).on('change', '.insights-type-filter-cb', this.controlsOnChange);
    $(html_ref.shared.current_view_id).on('change', '.insights-time-filter-cb', this.controlsOnChange);
    $(html_ref.shared.current_view_id).on('change', '.insights-var-filter-cb', this.controlsOnChange);

    $(html_ref.shared.current_view_id).on('click', '.show-hidden-insights-btn', this.showHiddenInsightsBtnOnClick);
    $(html_ref.shared.current_view_id).on('click', '.edit-insight-btn', this.editInsightBtnOnClick);
    $(html_ref.shared.current_view_id).on('click', '#edit-insight-send-btn', this.editInsightSendBtnOnClick);

    $(html_ref.shared.current_view_id).on('click', '#add-insight-btn', this.addInsightBtnOnClick);
    $(html_ref.shared.current_view_id).on('click', '#add-insight-add-btn', this.addInsightAddBtnOnClick);

    $(html_ref.shared.current_view_id).on('click', '#insights-selected-clear-btn', this.clearInsightBtnOnClick);

    $(html_ref.shared.current_view_id).on('click', '#insights-win-pct-selected-edit-btn', this.editInsightWinPctBtnOnClick);
    $(html_ref.shared.current_view_id).on('click', '#edit-insight-win-pct-send-btn', this.editInsightWinPctSendBtnOnClick);
    $(html_ref.shared.current_view_id).on('click', '#insights-win-pct-selected-clear-btn', this.clearInsightWinPctBtnOnClick);
    $(html_ref.shared.current_view_id).on('blur', '#edit-insight-win-pct-red-ti', this.editInsightWinPctTiOnBlur);
    $(html_ref.shared.current_view_id).on('blur', '#edit-insight-win-pct-blue-ti', this.editInsightWinPctTiOnBlur);
  };

  static renderInsights() {
    $(html_ref.shared.current_view_id).data('current-view', 'insights');
    $(html_ref.shared.current_view_id).addClass('insights-body');
    $(html_ref.shared.body_inner_id).addClass('insights-parent');
    $('.' + html_ref.shared.view_btn_class).removeClass('active-font');
    $(html_ref.shared.insights_btn_id).addClass('active-font');
    $(html_ref.shared.current_view_id).load('/screens/render_insights', {}, function(response, status, xhr) {
      if (status == 'error') {
        toastr.error('Unable to load insights view.');
      } else {
        $(html_ref.shared.current_view_id).removeClass('main-body detailed-body history-body');
        $(html_ref.shared.body_inner_id).removeClass('main-parent detailed-parent history-parent');
        insights.processed_insight_ids = [];
        insights.skip_inject_cycle = false;
        insights.StatsUtil.clearInsightsView();
        insights.StatsUtil.loadFighterNames();
      }
    });
  };

  static injectFightInsights(data) {
    if (insights.skip_inject_cycle) {
      insights.skip_inject_cycle = false;
      return;
    }

    if (insights.StatsUtil.isInsightsViewLoaded() && ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(data['FightId'])) && (data['ControlsString'] == insights.StatsUtil.insightsControlsStringRepresentation())) {
      let animation_class, dc_corner;
      let insight_rows = [];
      let tmp_indexed_insights = {};

      data['Insights'].forEach(function(insight) {
        dc_corner = insight.Corner ? insight.Corner.toLowerCase() : insight.Corner;

        // Avoid animation if this insight has already been shown
        let insight_not_yet_processed = (insights.processed_insight_ids.indexOf(insight.InsightId) === -1);
        animation_class = insight_not_yet_processed ? `${dc_corner}-row-animation` : '';
        
        insight_rows.unshift(insights.generateInsightRow(insight, true, animation_class, ''));

        if (insight_not_yet_processed) {
          insights.processed_insight_ids.push(insight.InsightId);
        }

        tmp_indexed_insights[insight.InsightId] = insight;
      });

      // Update nested insights object
      insights.indexed_insights = tmp_indexed_insights;

      // Inject data in DOM
      $(html_ref.insights.insights_holder_id).html(insight_rows);
      if (data['SelectedInsight'] !== null) {
        $('#insights-selected-edit-btn').data('insight-data', insights.encodeInsightData(data['SelectedInsight']));
        $('#insights-selected-clear-btn').data('insight-id', data['SelectedInsight'].InsightId);
        $('#insights-selected-text').text(data['SelectedInsight'].Description);
      } else {
        $('#insights-selected-edit-btn').data('insight-data', null);
        $('#insights-selected-clear-btn').data('insight-id', null);
        $('#insights-selected-text').text('');
      }
      if (data['RedWinProbability']) {
        $('#insights-red-win-pct').text(`${data['RedWinProbability']}%`);
      }
      if (data['BlueWinProbability']) {
        $('#insights-blue-win-pct').text(`${data['BlueWinProbability']}%`);
      }
      if (data['SelectedRedWinProbability'] && data['SelectedBlueWinProbability']) {
        $('#insights-red-win-pct').data('selected-win-pct', data['SelectedRedWinProbability']);
        $('#insights-blue-win-pct').data('selected-win-pct', data['SelectedBlueWinProbability']);
        $('#insights-win-pct-selected-text').text(`${data['SelectedRedWinProbability']}% / ${data['SelectedBlueWinProbability']}%`);
      } else {
        $('#insights-red-win-pct').data('selected-win-pct', null);
        $('#insights-blue-win-pct').data('selected-win-pct', null);
        $('#insights-win-pct-selected-text').text('');
      }

      // Show
      insights.StatsUtil.showInsightsView();
    }
  };

  controlsOnChange() {
    insights.StatsUtil.showInsightsViewLoader();
    $(html_ref.insights.insights_holder_id).html('');
  };

  showHiddenInsightsBtnOnClick() {
    $('#hidden-insights-modal-insights-holder').html('');

    $('#hidden-insights-modal-insights-holder').append(insights.generateDividerInsightRow('MAIN INSIGHT'));

    let parent_insight = insights.indexed_insights[$(this).data('insight-id')];
    $('#hidden-insights-modal-insights-holder').append(insights.generateInsightRow(parent_insight, false, '', ''));

    let insight_rows = [];
    parent_insight['NestedInsights'].forEach(function(insight) {
      insight_rows.unshift(insights.generateInsightRow(insight, false, '', ''));
    });

    $('#hidden-insights-modal-insights-holder').append(insights.generateDividerInsightRow(`HIDDEN INSIGHTS (${parent_insight['NestedInsights'].length})`));
    $('#hidden-insights-modal-insights-holder').append(insight_rows);

    $('#hidden-insights-modal').modal('show');
    $("#hidden-insights-modal-insights-table-holder").animate({scrollTop: $("#hidden-insights-modal-insights-table-holder").offset().top});
  };

  addInsightBtnOnClick() {
    $('#add-insight-commentator-description-ta').val('');
    $('#add-insight-gfx-stat-name-ti').val('');
    $('#add-insight-gfx-stat-value-ti').val('');
    $('#add-insight-gfx-description-ta').val('');

    $('#add-insight-modal').modal('show');
  };

  addInsightAddBtnOnClick() {
    if (!$(this).hasClass('disabled')) {
      $(this).addClass('disabled');
      $(this).text('Adding...');
      $.ajax({
        url: '/screens/ajax_post_gfx_insight_add',
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: {
          fight_id: $(html_ref.shared.current_view_id).data('picked-fight-id'),
          corner: $('input[type=radio][name=add-insight-corner]:checked').val(),
          type: $('#add-insight-type-select').val(),
          description: $('#add-insight-commentator-description-ta').val(),
          gfx_stat_name: $('#add-insight-gfx-stat-name-ti').val(),
          gfx_stat_value: $('#add-insight-gfx-stat-value-ti').val(),
          gfx_description: $('#add-insight-gfx-description-ta').val(),
          applies_to: 'fighter',
          score: 0
        },
        success: function(data) {
          if (data['success']) {
            setTimeout(function(){
              toastr.success(`Milestone Added.`);
              $('#add-insight-add-btn').removeClass('disabled');
              $('#add-insight-add-btn').text('Add');
              $('#add-insight-modal').modal('hide');
            }, 2000);
          } else {
            $('#add-insight-add-btn').removeClass('disabled');
            $('#add-insight-add-btn').text('Add');
            toastr.error(`Insight could not be added. Please try again. [API ${data['code']}]`);
          }
        },
        error: function(data) {
          $('#add-insight-add-btn').removeClass('disabled');
          $('#add-insight-add-btn').text('Add');
          toastr.error('Insight could not be added. Please try again. [INTERNAL]');
        }
      });
    }
  };

  editInsightBtnOnClick() {
    if ($(this).data('insight-data')) {
      $('#edit-insight-encoded-data-ti').val($(this).data('insight-data'));

      let insight_data = insights.decodeInsightData($(this).data('insight-data'));      
      $('#edit-insight-id-ti').val(insight_data.InsightId);    
      $('#edit-insight-gfx-stat-name-ti').val(insight_data.GfxStatName ? insight_data.GfxStatName : insight_data.StatName);
      $('#edit-insight-gfx-stat-value-ti').val(insight_data.GfxStatValue ? insight_data.GfxStatValue : insight_data.StatValue);
      $('#edit-insight-gfx-description-ta').val(insight_data.GfxDescription ? insight_data.GfxDescription : insights.insightDescriptionToGfxDescription(insight_data));
      $('#edit-insight-commentator-description-ta').val(insight_data.GfxDescription ? insight_data.Description : insights.insightDescriptionToCommentatorDescription(insight_data));

      $('#edit-insight-modal').modal('show');
    }
  };

  editInsightSendBtnOnClick() {
    if (!$(this).hasClass('disabled')) {
      $(this).addClass('disabled');
      $(this).text('Posting...');
      $.ajax({
        url: '/screens/ajax_post_gfx_insight_send',
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: {
          fight_id: $(html_ref.shared.current_view_id).data('picked-fight-id'),
          insight_id: $('#edit-insight-id-ti').val(),
          description: $('#edit-insight-commentator-description-ta').val(),
          gfx_stat_name: $('#edit-insight-gfx-stat-name-ti').val(),
          gfx_stat_value: $('#edit-insight-gfx-stat-value-ti').val(),
          gfx_description: $('#edit-insight-gfx-description-ta').val()
        },
        success: function(data) {
          if (data['success']) {
            insights.skip_inject_cycle = true;

            $(`.insight.active`).each(function(){
              $(this).removeClass('active');
            });

            $(`.insight.${$('#edit-insight-id-ti').val()}`).each(function(){
              $(this).addClass('active');
            });

            $('#insights-selected-edit-btn').data('insight-data', $('#edit-insight-encoded-data-ti').val());
            $('#insights-selected-clear-btn').data('insight-id', $('#edit-insight-id-ti').val());
            $('#insights-selected-text').text($('#edit-insight-commentator-description-ta').val());

            $(`.description-column.${$('#edit-insight-id-ti').val()}`).each(function(){
              $(this).text($('#edit-insight-commentator-description-ta').val());
            });

            $(`.stat-name-column.${$('#edit-insight-id-ti').val()}`).each(function(){
              if ($(this).data('original-stat-name') && (String($(this).data('original-stat-name')) !== $('#edit-insight-gfx-stat-name-ti').val())) {
                $(this).html(insights.generateDualStatName($(this).data('original-stat-name'), $('#edit-insight-gfx-stat-name-ti').val()));
              } else {
                $(this).html($(this).data('original-stat-name') ? $(this).data('original-stat-name') : $('#edit-insight-gfx-stat-name-ti').val());
              }
            });

            $(`.value-column.${$('#edit-insight-id-ti').val()}`).each(function(){
              if ($(this).data('original-value') && (String($(this).data('original-value')) !== $('#edit-insight-gfx-stat-value-ti').val())) {
                $(this).html(insights.generateDualStatValue($(this).data('original-value'), $('#edit-insight-gfx-stat-value-ti').val()));
              } else {
                $(this).html($(this).data('original-value') ? $(this).data('original-value') : $('#edit-insight-gfx-stat-value-ti').val());
              }
            });

            setTimeout(function(){
              toastr.success(`Insight Posted.`);
              $('#edit-insight-send-btn').removeClass('disabled');
              $('#edit-insight-send-btn').text('Post');
              $('#hidden-insights-modal').modal('hide');
              $('#edit-insight-modal').modal('hide');
            }, 250);            
          } else {
            $('#edit-insight-send-btn').removeClass('disabled');
            $('#edit-insight-send-btn').text('Post');
            toastr.error(`Insight could not be posted. Please try again. [API ${data['code']}]`);
          }
        },
        error: function(data) {
          $('#edit-insight-send-btn').removeClass('disabled');
          $('#edit-insight-send-btn').text('Post');
          toastr.error('Insight could not be posted. Please try again. [INTERNAL]');
        }
      });
    }
  };

  clearInsightBtnOnClick() {
    if ($(this).data('insight-id') && !$(this).hasClass('disabled')) {
      $(this).addClass('disabled');
      $.ajax({
        url: '/screens/ajax_post_gfx_insight_clear',
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: {
          fight_id: $(html_ref.shared.current_view_id).data('picked-fight-id')
        },
        success: function(data) {
          if (data['success']) {
            insights.skip_inject_cycle = true;

            $(`.insight.active`).each(function(){
              $(this).removeClass('active');
            });

            $('#insights-selected-edit-btn').data('insight-data', null);
            $('#insights-selected-clear-btn').data('insight-id', null);
            $('#insights-selected-text').text('');

            setTimeout(function(){
              toastr.success(`Insight Cleared.`);
              $('#insights-selected-clear-btn').removeClass('disabled');
            }, 250);            
          } else {
            $('#insights-selected-clear-btn').removeClass('disabled');
            toastr.error(`Insight could not be cleared. Please try again. [API ${data['code']}]`);
          }
        },
        error: function(data) {
          $('#insights-selected-clear-btn').removeClass('disabled');
          toastr.error('Insight could not be cleared. Please try again. [INTERNAL]');
        }
      });
    }
  };

  editInsightWinPctBtnOnClick() {
    let red_pct = $('#insights-red-win-pct').data('selected-win-pct') ? $('#insights-red-win-pct').data('selected-win-pct') : $('#insights-red-win-pct').text().replace('%','');
    let blue_pct = $('#insights-blue-win-pct').data('selected-win-pct') ? $('#insights-blue-win-pct').data('selected-win-pct') : $('#insights-blue-win-pct').text().replace('%','');
    red_pct = (typeof red_pct === 'string') ? red_pct.trim() : red_pct;
    blue_pct = (typeof blue_pct === 'string') ? blue_pct.trim() : blue_pct;
    $('#edit-insight-win-pct-red-ti').val(red_pct);
    $('#edit-insight-win-pct-blue-ti').val(blue_pct);

    $('#edit-insight-win-pct-modal').modal('show');
  };

  editInsightWinPctTiOnBlur() {
    let number_value = parseInt($(this).val());
    if ((this.id === 'edit-insight-win-pct-red-ti') && (typeof number_value === 'number')) {
      $('#edit-insight-win-pct-blue-ti').val(100 - number_value);
    } else if ((this.id === 'edit-insight-win-pct-blue-ti') && (typeof number_value === 'number')) {
      $('#edit-insight-win-pct-red-ti').val(100 - number_value);
    }
  };

  editInsightWinPctSendBtnOnClick() {
    if (!$(this).hasClass('disabled')) {
      $(this).addClass('disabled');
      $(this).text('Posting...');
      $.ajax({
        url: '/screens/ajax_post_gfx_insight_win_pct_send',
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: {
          fight_id: $(html_ref.shared.current_view_id).data('picked-fight-id'),
          red_win_pct: $('#edit-insight-win-pct-red-ti').val(),
          blue_win_pct: $('#edit-insight-win-pct-blue-ti').val()
        },
        success: function(data) {
          if (data['success']) {
            insights.skip_inject_cycle = true;

            $('#insights-win-pct-selected-text').text(`${$('#edit-insight-win-pct-red-ti').val()}% / ${$('#edit-insight-win-pct-blue-ti').val()}%`);
            $('#insights-red-win-pct').data('selected-win-pct', $('#edit-insight-win-pct-red-ti').val());
            $('#insights-blue-win-pct').data('selected-win-pct', $('#edit-insight-win-pct-blue-ti').val());

            setTimeout(function(){
              toastr.success(`Win Percentage Posted.`);
              $('#edit-insight-win-pct-send-btn').removeClass('disabled');
              $('#edit-insight-win-pct-send-btn').text('Post');
              $('#edit-insight-win-pct-modal').modal('hide');
            }, 250);
          } else {
            $('#edit-insight-win-pct-send-btn').removeClass('disabled');
            $('#edit-insight-win-pct-send-btn').text('Post');
            toastr.error(`Win pct could not be posted. Please try again. [API ${data['code']}]`);
          }
        },
        error: function(data) {
          $('#edit-insight-win-pct-send-btn').removeClass('disabled');
          $('#edit-insight-win-pct-send-btn').text('Post');
          toastr.error('Win pct could not be posted. Please try again. [INTERNAL]');
        }
      });
    }
  };

  clearInsightWinPctBtnOnClick() {
    if ($('#insights-red-win-pct').data('selected-win-pct') && !$(this).hasClass('disabled')) {
      $(this).addClass('disabled');
      $.ajax({
        url: '/screens/ajax_post_gfx_insight_win_pct_clear',
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: {
          fight_id: $(html_ref.shared.current_view_id).data('picked-fight-id')
        },
        success: function(data) {
          if (data['success']) {
            insights.skip_inject_cycle = true;

            $('#insights-win-pct-selected-text').text('');
            $('#insights-red-win-pct').data('selected-win-pct', null);
            $('#insights-blue-win-pct').data('selected-win-pct', null);

            setTimeout(function(){
              toastr.success(`Win Percentage Cleared.`);
              $('#insights-win-pct-selected-clear-btn').removeClass('disabled');
            }, 250);
          } else {
            $('#insights-win-pct-selected-clear-btn').removeClass('disabled');
            toastr.error(`Win pct could not be cleared. Please try again. [API ${data['code']}]`);
          }
        },
        error: function(data) {
          $('#insights-win-pct-selected-clear-btn').removeClass('disabled');
          toastr.error('Win pct could not be cleared. Please try again. [INTERNAL]');
        }
      });
    }
  };

  insightDescriptionToCommentatorDescription(insight) {
    let commentator_description;
    try {
      if (insight.Type == 'Most') {
        commentator_description = insight.Description.slice(0,-1) + ` vs ${insight.PreviousRecord.Opponent.Name.FirstName} ${insight.PreviousRecord.Opponent.Name.LastName} on ${insights.getFormattedPreviousRecordEventDate(insight)}.`;
      } else if (insight.Type == 'Last Time') {
        commentator_description = insight.Description.split(' on ')[0] + ` vs ${insight.PreviousRecord.Opponent.Name.FirstName} ${insight.PreviousRecord.Opponent.Name.LastName} on ${insights.getFormattedPreviousRecordEventDate(insight)}.`;
      } else if (insight.Type == 'Record in Fights') {
        commentator_description = insight.Description.replace('than opponent landed', 'landed than their opponent');
      } else {
        commentator_description = insight.Description;
      }
    } catch (error) {
      commentator_description = insight.Description;
    }
    return commentator_description;
  };

  insightDescriptionToGfxDescription(insight) {
    let gfx_description;
    try {
      if (insight.Type == 'Most') {
        gfx_description = `${insights.StatsUtil.capitalizeFirstLetter(insight.Description.split('. The')[0].split(', the ').pop())}.`;
      } else if (insight.Type == 'Record in Fights') {
        gfx_description = insight.Description.split('. ').pop().replace('than opponent landed', 'landed than their opponent');
      } else if (insight.Type == 'Last Time') {
        gfx_description = insight.Description.split('. ').pop().split(' on ')[0] + ` on ${insights.getFormattedPreviousRecordEventDate(insight)}.`;
      } else { // First time
        gfx_description = insight.Description;
      }
    } catch (error) {
      gfx_description = insight.Description;
    }
    return gfx_description;
  };

  generateInsightRow(insight, add_nested_count, animation_class, style) {
    let dc_corner = insight.Corner ? insight.Corner.toLowerCase() : insight.Corner;
    let stat_value;
    if (insight.StatValue === null) {
      stat_value = (insight.GfxStatValue !== null) ? insight.GfxStatValue : '';
    } else if ((insight.GfxStatValue !== null) && (insight.StatValue !== insight.GfxStatValue)) {
      stat_value = insights.generateDualStatValue(insight.StatValue, insight.GfxStatValue);
    } else {
      stat_value = insight.StatValue;
    }
    let stat_name;
    if (insight.StatName === null) {
      stat_name = (insight.GfxStatName !== null) ? insight.GfxStatName : '';
    } else if ((insight.GfxStatName !== null) && (insight.StatName !== insight.GfxStatName)) {
      stat_name = insights.generateDualStatName(insight.StatName, insight.GfxStatName);
    } else {
      stat_name = insight.StatName;
    }
    return `
      <tr class="insight ${insight.InsightId} ${(insight.GfxOrder === null) ? '' : 'active'} ${animation_class}" style="${style}">
        <td class="corner-column corner-column-filter-by ${dc_corner}-insight">${insight.Corner}</td>
        <td>${(new Date(insight.Timestamp)).toLocaleTimeString()}${(add_nested_count && insight.NestedInsights && (insight.NestedInsights.length > 0)) ? ('<br/><a class="show-hidden-insights-btn" data-insight-id="' + insight.InsightId + '">(' + insight.NestedInsights.length + ' Hidden)</a>') : ''}</a></td>
        <td>${insight.Score}</td>
        <td class="type-column-filter-by">${insight.Type}</td>
        <td class="time-column-filter-by">${(insight.TimePeriod === null) ? '' : insight.TimePeriod}</td>
        <td class="vars-column-filter-by">${insight.Variables.join(', ')}</td>
        <td class="stat-name-column ${insight.InsightId}" data-original-stat-name="${(insight.StatName === null) ? '' : insight.StatName}">${stat_name}</td>
        <td class="value-column ${insight.InsightId}" data-original-value="${(insight.StatValue === null) ? '' : insight.StatValue}">${stat_value}</td>
        <td class="description-column ${insight.InsightId}">${insight.Description}</td>
        <td>
          <button type="button" class="btn btn-dark edit-insight-btn" data-insight-data=${insights.encodeInsightData(insight)}>
            <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" aria-hidden="true"><use xlink:href="#icon__cloudSend"></use></svg>
          </button>
        </td>
      </tr>
    `;
  };

  getFormattedPreviousRecordEventDate(insight) {
    let event_date;
    try {
      if ((Object.keys(insight.PreviousRecord).length !== 0) && (Object.keys(insight.PreviousRecord.Event).length !== 0)) {
        // We flip the +/- of the timezone to create a UTC timestamp that reflects the date correctly to print with toDateString()
        let flipped_timezone = (insight.PreviousRecord.Event.TimeZone === 'GMT+00:00') ? insight.PreviousRecord.Event.TimeZone : (insight.PreviousRecord.Event.TimeZone.indexOf('-') === -1) ? insight.PreviousRecord.Event.TimeZone.replace('+', '-') : insight.PreviousRecord.Event.TimeZone.replace('-', '+');
        event_date = (new Date(insight.PreviousRecord.Event.StartTime.replace('Z','') + flipped_timezone.replace('GMT','')));
        event_date = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }).format(event_date);
      }
    } catch (error) {
      event_date = '';
    }
    return event_date;
  };

  encodeInsightData(insight) {
    return btoa(JSON.stringify(insight, ['InsightId', 'Type', 'StatName', 'GfxStatName', 'StatValue', 'GfxStatValue', 'Description', 'GfxDescription', 'PreviousRecord', 'Event', 'StartTime', 'TimeZone', 'Fighter', 'Opponent', 'Name', 'FirstName', 'LastName']));
  };

  decodeInsightData(encoded_insight_data) {
    return JSON.parse(atob(encoded_insight_data));
  };

  generateDividerInsightRow(title) {
    return `
      <tr>
        <td colspan="10" class="row-divider">${title}</td>
      </tr>
    `;
  };

  generateDualStatName(original_stat_name, gfx__stat_name) {
    return `<span class="original-stat-name">${original_stat_name}</span><br/><span class="gfx-stat-name">${gfx__stat_name}</span>`
  };

  generateDualStatValue(original_value, gfx_value) {
    return `<span class="original-value">${original_value}</span><br/><span class="gfx-value">${gfx_value}</span>`
  };

}

export let insights = new Insights();