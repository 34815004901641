// Action cable
import consumer from '../channels/consumer'

// Other
import { StatsUtil } from '../util/stats_util';
import { Main } from '../screens/main';
import { Detailed } from '../screens/detailed';
import { History } from '../screens/history';
import { Insights } from '../screens/insights';
import { MenuBar, menu_bar } from './menu_bar';
import { live_stats_loader } from './live_stats_loader';
import { CsChannel, cs_channel } from './cs_channel';

menu_bar.init(CsChannel, StatsUtil, Main, Detailed, History, Insights);
if (StatsUtil.inStatsView()) {
  live_stats_loader.init(StatsUtil, MenuBar, Main, Detailed, History, Insights);
}
cs_channel.init(consumer, StatsUtil, MenuBar);