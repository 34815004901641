class TalentCues {

  init() {
    this.current_talent_cue_id = $(html_ref.talent_cues.talent_cues_table_body_id).find('tr.' + html_ref.talent_cues.talent_cues_table_active_row_class).length ? $(html_ref.talent_cues.talent_cues_table_body_id).find('tr.' + html_ref.talent_cues.talent_cues_table_active_row_class).data('id') : null;

    $(html_ref.talent_cues.import_talent_cues_spreadsheet_btn_id).on('click', this.onImportSpreadsheetBtnClick);
    $(html_ref.talent_cues.clear_talent_cue_btn_id).on('click', this.onClearTalentCueClick);
    $(html_ref.talent_cues.send_next_talent_cue_btn_id).on('click', this.onSendNextTalentCueClick);
    $(html_ref.shared.current_view_id).on('click', '.' + html_ref.talent_cues.send_talent_cue_btn_class, this.onSendTalentCueClick);
    $(html_ref.shared.current_view_id).on('click', '.' + html_ref.talent_cues.clear_talent_cue_btn_class, this.onClearTalentCueClick);

    if (this.current_talent_cue_id != null) {
      setTimeout(talent_cues.goToTalentCue, 100, this.current_talent_cue_id);
    }
  };

  onSendTalentCueClick() {
    talent_cues.sendTalentCue($(this).data('id'));
  };

  onSendNextTalentCueClick() {
    let next_talent_cue = talent_cues.getNextTalentCue();
    if (next_talent_cue != null) {
      talent_cues.sendTalentCue(next_talent_cue.id);
      talent_cues.goToTalentCue(next_talent_cue.id);
    }
  };  

  onClearTalentCueClick() {
    var talent_cue_id = $(this).data('id');
    $.ajax({
      url: '/screens/ajax_clear_talent_cue',
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data: {},
      success: function(data) {
        if (data['success']) {
          talent_cues.resetTalentCuesTable();
          if (talent_cue_id) {
            $('.' + html_ref.talent_cues.send_talent_cue_btn_class + '[data-id="' + talent_cue_id + '"]').show();
            $('.' + html_ref.talent_cues.clear_talent_cue_btn_class + '[data-id="' + talent_cue_id + '"]').hide();
          }
        } else {
          toastr.error('Cue NOT cleared. Please try again.');
        }
      },
      error: function(data) {
        toastr.error('Cue NOT cleared. Please try again.');
      }
    });
  };

  onImportSpreadsheetBtnClick() {
    let yes = confirm('Are you sure? The current cues will be overwritten.');
    if ($(html_ref.talent_cues.talent_cues_spreadsheet_name_id).val() && yes) {
      $(html_ref.talent_cues.import_talent_cues_spreadsheet_btn_id).text('Importing...');
      $.ajax({
        url: '/screens/ajax_import_talent_cues',
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: {
          spreadsheet_name: $(html_ref.talent_cues.talent_cues_spreadsheet_name_id).val()
        },
        success: function(data) {
          if (data['success']) {
            $('#talent-cues-table-holder').load('/screens/render_talent_cues_table', {}, function(response, status, xhr) {
              if (status == 'error') {
                toastr.error('Unable to reload cues table. Please refresh the page.');
              } else {
                toastr.success('Cues have been successfully imported.')
                talent_cues.resetTalentCuesTable();
                // Close menu
                if ($('.' + html_ref.shared.menu_bars_class).hasClass('open')) {
                  $('.' + html_ref.shared.menu_bars_class).click();
                }
              }
              $(html_ref.talent_cues.import_talent_cues_spreadsheet_btn_id).text('Import');
            });
          } else {
            toastr.error(data['message']);
            $(html_ref.talent_cues.import_talent_cues_spreadsheet_btn_id).text('Import');
          }
        },
        error: function(data) {
          toastr.error('Unable to load cues Google Sheet.');
          $(html_ref.talent_cues.import_talent_cues_spreadsheet_btn_id).text('Import');
        }
      });
    }
  };

  sendTalentCue(talent_cue_id) {
    $.ajax({
      url: '/screens/ajax_send_talent_cue',
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data: {
        id: talent_cue_id
      },
      success: function(data) {
        if (data['success']) {
          $('.' + html_ref.talent_cues.talent_cues_table_row_class + '.' + html_ref.talent_cues.talent_cues_table_active_row_class).removeClass(html_ref.talent_cues.talent_cues_table_active_row_class);
          $('.' + html_ref.talent_cues.talent_cues_table_row_class + '[data-id="' + talent_cue_id + '"]').addClass(html_ref.talent_cues.talent_cues_table_active_row_class);
          $('.' + html_ref.talent_cues.send_talent_cue_btn_class + ':hidden').show();
          $('.' + html_ref.talent_cues.clear_talent_cue_btn_class + ':visible').hide();
          $('.' + html_ref.talent_cues.send_talent_cue_btn_class + '[data-id="' + talent_cue_id + '"]').hide();
          $('.' + html_ref.talent_cues.clear_talent_cue_btn_class + '[data-id="' + talent_cue_id + '"]').show();
          talent_cues.current_talent_cue_id = talent_cue_id;
        } else {
          toastr.error('Cue NOT sent. Please try again.');
        }
      },
      error: function(data) {
        toastr.error('Cue NOT sent. Please try again.');
      }
    });
  };

  goToTalentCue(id) {
    let url = location.href;
    location.href = '#talent-cue-' + String(id);
    history.replaceState(null, null, url);
  };  

  getNextTalentCue() {
    if ($(html_ref.talent_cues.talent_cues_table_body_id).children().length) {
      let next_element;
      if (talent_cues.current_talent_cue_id == null) {
        next_element = $(html_ref.talent_cues.talent_cues_table_body_id).children().not('.' + html_ref.talent_cues.talent_cues_table_row_separator_class).first();
      } else {
        next_element = $(html_ref.talent_cues.talent_cues_table_body_id).children('tr.' + html_ref.talent_cues.talent_cues_table_row_class + '.' + html_ref.talent_cues.talent_cues_table_active_row_class + ' ~ tr.' + html_ref.talent_cues.talent_cues_table_row_class + ':not(.' + html_ref.talent_cues.talent_cues_table_row_separator_class + ')').first();
      }
      if (next_element.length) {
        return {id: next_element.data('id'), order: next_element.data('order')}
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  resetTalentCuesTable() {
    $('.' + html_ref.talent_cues.talent_cues_table_row_class + '.' + html_ref.talent_cues.talent_cues_table_active_row_class).removeClass(html_ref.talent_cues.talent_cues_table_active_row_class);
    talent_cues.current_talent_cue_id = null;
  };

}

export let talent_cues = new TalentCues();