// Note: class reference values don't include '.' so they can be used in addClass / removeClass functions

export default {
  shared: {
    body_inner_id: '#body-inner',
    current_view_id: '#current-view',
    view_btn_class: 'view-btn',
    main_btn_id: '#main-btn',
    detailed_btn_id: '#detailed-btn',
    history_btn_id: '#history-btn',
    insights_btn_id: '#insights-btn',
    menu_bars_class: 'menu-bars',
    slider_class: 'slider',
    header_class: 'header',
    header_total_id: '#header-total',
    check_round_class: 'check-round',
    round_menu_class: 'round-menu',
    fight_status_holder_parent_id: '#fight-status-holder-parent',
    fight_status_holder_id: '#fight-status-holder',
    fight_clock_holder_id: '#fight-clock-holder',
    red_firstname_class: 'red-firstname',
    red_lastname_class: 'red-lastname',
    blue_firstname_class: 'blue-firstname',
    blue_lastname_class: 'blue-lastname',
    wc_abbr_class: 'wc-abbr',
    red_blue_of_class: 'redblue-of'
  },
  sidebar: {
    sidebar_id: '#sidebar',
    // Event / fight list
    event_list_id: '#event-list',
    fight_list_id: '#fight-list',
    fight_list_spinner_id: '#fight-list-spinner',
    fight_btn_class: 'fight-btn',
    // Settings
    sidebar_data_source_id: '#sidebar-data-source',
    sidebar_fight_advancement_mode_id: '#sidebar-fight-advancement-mode',
    sidebar_automatic_fight_advancement_id: '#sidebar-automatic-fight-advancement',
    // Data
    picked_fight_red_firstname_id: '#picked_fight_red_firstname',
    picked_fight_red_lastname_id: '#picked_fight_red_lastname',
    picked_fight_blue_firstname_id: '#picked_fight_blue_firstname',
    picked_fight_blue_lastname_id: '#picked_fight_blue_lastname',
    picked_fight_wc_abbr_id: '#picked_fight_wc_abbr'
  },
  main: {
    main_body_left_stats_holder_id: '#main-body-left-stats-holder',
    main_body_left_overlay_spinner_id: '#main-body-left-overlay-spinner',
    main_body_right_stats_holder_id: '#main-body-right-stats-holder',
    main_body_right_overlay_spinner_id: '#main-body-right-overlay-spinner',
    // Notifications
    notif_holder_id: '#notif-holder',
    notif_text_id: '#notif-text',
    notif_user_time_id: '#notif-user-time',
    // Talent Cues
    talent_cue_holder_id: '#talent-cue-holder',
    talent_cue_block_class: 'talent-cue-block'
  },
  detailed: {
    detailed_body_left_id: '#detailed-body-left',
    detailed_body_right_id: '#detailed-body-right',
    detailed_body_overlay_spinner_id: '#detailed-body-overlay-spinner',
    // Expandable sections
    detailed_red_plus_id: '#detailed-red-plus',
    detailed_blue_plus_id: '#detailed-blue-plus',
    detailed_expand_ss_id: '#detailed-expand-ss',
    detailed_ss_section_id: '#detailed-ss-section',
    detailed_expand_ts_id: '#detailed-expand-ts',
    detailed_ts_section_id: '#detailed-ts-section',
    detailed_expand_ct_id: '#detailed-expand-ct',
    detailed_ct_section_id: '#detailed-ct-section',
    // Matrix
    detailed_matrix_btn_id: '#detailed-matrix-btn',
    matrix_block_margin_id: '#matrix-block-margin',
    // Round by Round
    detailed_rbr_btn_id: '#detailed-rbr-btn',
    rbr_block_id: '#rbr-block',
    rbr_loading_id: '#rbr-loading',
    rbr_autocomplete_id: '#rbr-autocomplete',
    // Notifications
    notif_new_btn_id: '#notif-new-btn',
    notif_new_btn_text_id: '#notif-new-btn-txt',
    notif_new_btn_plus_sign_id: '#notif-new-btn-plus-sign',
    notif_form_holder_id: '#notif-form-holder',
    notif_post_btn_id: '#notif-post-btn',
    notif_clear_btn_id: '#notif-clear-btn',
    notif_form_text_id: '#notif-form-text',
    notif_holder_id: '#notif-holder',
    notif_text_id: '#notif-text',
    notif_user_time_id: '#notif-user-time'
  },
  history: {
    history_body_left_id: '#history-body-left',
    history_body_right_id: '#history-body-right',
    history_body_overlay_spinner_id: '#history-body-overlay-spinner'
  },
  insights: {
    insights_body_id: '#insights-body',
    insights_holder_id: '#insights-holder',
    insights_body_overlay_spinner_id: '#insights-body-overlay-spinner',
    insights_controls_sort_by_checked: 'input[type=radio][name=insights-sort-by]:checked',
    insights_controls_filter_corner_checked: 'input.insights-corner-filter-cb:checked',
    insights_controls_filter_type_checked: 'input.insights-type-filter-cb:checked',
    insights_controls_filter_var_checked: 'input.insights-var-filter-cb:checked',
    insights_controls_filter_time_checked: 'input.insights-time-filter-cb:checked'
  },
  talent_cues: {
    // Import
    import_talent_cues_spreadsheet_btn_id: '#import-talent-cues-spreadsheet-btn',
    talent_cues_spreadsheet_name_id: '#talent-cues-spreadsheet-name',
    // Send / Clear
    clear_talent_cue_btn_id: '#clear-talent-cue-btn',
    send_next_talent_cue_btn_id: '#send-next-talent-cue-btn',
    send_talent_cue_btn_class: 'send-talent-cue-btn',
    clear_talent_cue_btn_class: 'clear-talent-cue-btn',
    // Table
    talent_cues_table_body_id: '#talent-cues-table-body',
    talent_cues_table_row_class: 'talent-cue-row',
    talent_cues_table_active_row_class: 'cuesTable--rowActive',
    talent_cues_table_row_separator_class: 'cuesTable--rowSeparator'
  }
}