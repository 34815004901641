class LiveStatsLoader {

  init(stats_util_class_ref, menu_bar_class_ref, main_class_ref, detailed_class_ref, history_class_ref, insights_class_ref) {
    this.fight_stats = null;
    this.load_stats_finished = true;
    this.load_odds_finished = true;
    this.load_insights_finished = true;
    this.load_live_ids_finished = true;

    this.StatsUtil = stats_util_class_ref;
    this.MenuBar = menu_bar_class_ref;
    this.Main = main_class_ref;
    this.Detailed = detailed_class_ref;
    this.History = history_class_ref;
    this.Insights = insights_class_ref;

    // Interval to load live stats
    setInterval(this.loadFightStats, 1500);

    // Interval to load live ids
    setInterval(this.loadLiveIds, 5000);
  };

  loadFightStats() {
    if (live_stats_loader.load_stats_finished) {
      if (live_stats_loader.StatsUtil.isMainViewLoaded()) {
        live_stats_loader.load_stats_finished = false;
        live_stats_loader.StatsUtil.getFightStats(live_stats_loader.Main.injectFightStats, live_stats_loader.loadFightStatsSuccess, live_stats_loader.loadFightStatsError);
      } else if (live_stats_loader.StatsUtil.isDetailedViewLoaded()) {
        live_stats_loader.load_stats_finished = false;
        live_stats_loader.StatsUtil.getFightStats(live_stats_loader.Detailed.injectFightStats, live_stats_loader.loadFightStatsSuccess, live_stats_loader.loadFightStatsError);
      } else if (live_stats_loader.StatsUtil.isHistoryViewLoaded()) {
        live_stats_loader.load_stats_finished = false;
        live_stats_loader.StatsUtil.getFightStats(live_stats_loader.History.injectFightStats, live_stats_loader.loadFightStatsSuccess, live_stats_loader.loadFightStatsError);
      }
    }

    if (live_stats_loader.load_odds_finished) {
      if (live_stats_loader.StatsUtil.isMainViewLoaded()) {
        live_stats_loader.load_odds_finished = false;
        live_stats_loader.StatsUtil.getFightOdds(live_stats_loader.Main.injectFightOdds, live_stats_loader.loadFightOddsSuccess, live_stats_loader.loadFightOddsError);
      } else if (live_stats_loader.StatsUtil.isDetailedViewLoaded()) {
        live_stats_loader.load_odds_finished = false;
        live_stats_loader.StatsUtil.getFightOdds(live_stats_loader.Detailed.injectFightOdds, live_stats_loader.loadFightOddsSuccess, live_stats_loader.loadFightOddsError);
      }
    }

    if (live_stats_loader.load_insights_finished) {
      if (live_stats_loader.StatsUtil.isInsightsViewLoaded()) {
        live_stats_loader.load_insights_finished = false;
        live_stats_loader.StatsUtil.getFightInsights(live_stats_loader.Insights.injectFightInsights, live_stats_loader.loadFightInsightsSuccess, live_stats_loader.loadFightInsightsError);
      }
    }
  };

  loadFightStatsSuccess() {
    live_stats_loader.load_stats_finished = true;
  };

  loadFightStatsError() {
    live_stats_loader.load_stats_finished = true;
  };

  loadFightOddsSuccess() {
    live_stats_loader.load_odds_finished = true;
  };

  loadFightOddsError() {
    $('.red-live-dk-live-odds').text('');
    $('.blue-live-dk-live-odds').text('');
    live_stats_loader.load_odds_finished = true;
  };

  loadFightInsightsSuccess() {
    live_stats_loader.load_insights_finished = true;
  };

  loadFightInsightsError() {
    live_stats_loader.load_insights_finished = true;
  };

  loadLiveIds() {
    if (live_stats_loader.load_live_ids_finished) {
      live_stats_loader.load_live_ids_finished = false;

      $.ajax({
        url: '/screens/ajax_live_ids',
        method: 'GET',
        timeout: 3000,
        data: {},
        success: function(data) {
          // Move live indicator
          if (data['LiveFightId'] && $('.' + html_ref.sidebar.fight_btn_class + '[data-fight-id="' + String(data['LiveFightId']) + '"]').length && !$('.' + html_ref.sidebar.fight_btn_class + '[data-fight-id="' + String(data['LiveFightId']) + '"]').data('live')) {            
            $('.' + html_ref.sidebar.fight_btn_class).data('live', false).attr('data-live', false);
            $('.' + html_ref.sidebar.fight_btn_class + '[data-fight-id="' + String(data['LiveFightId']) + '"]').data('live', true).attr('data-live', true);
            $('.' + html_ref.sidebar.fight_btn_class + '[data-fight-id="' + String(data['LiveFightId']) + '"] .live-holder').html('<div class="live">LIVE</div>');
          } else if (!data['LiveFightId'] && $('.' + html_ref.sidebar.fight_btn_class + '[data-live="true"]').length) {
            $('.' + html_ref.sidebar.fight_btn_class + '[data-live="true"]').find('.live-holder').html('<div class="over">OVER</div>');
            $('.' + html_ref.sidebar.fight_btn_class).data('live', false).attr('data-live', false);            
          }

          // Advance fight for clients if in API mode
          if (data['LiveEventId'] && data['LiveFightId'] && ($(html_ref.shared.current_view_id).data('picked-fight-id') != data['LiveFightId'])
              && ($(html_ref.sidebar.sidebar_id).data('user-role') == 'client') && ($(html_ref.sidebar.sidebar_id).data('fight-advancement-mode') == 'api')
              && ($(html_ref.sidebar.sidebar_automatic_fight_advancement_id).val() == 'on')) {
            // Clear notification if any
            setTimeout(live_stats_loader.StatsUtil.clearNotification, 4000);
            // Load fight
            if ($(html_ref.shared.current_view_id).data('picked-event-id') != data['LiveEventId']) {
              live_stats_loader.MenuBar.loadEventFightList(data['LiveEventId'], function(){
                live_stats_loader.MenuBar.loadFight(data['LiveEventId'], data['LiveFightId']);
              });
            } else {
              live_stats_loader.MenuBar.loadFight(data['LiveEventId'], data['LiveFightId']);
            }
          }          

          live_stats_loader.loadLiveIdsSuccess();
        },
        error: function(data) {
          live_stats_loader.loadLiveIdsError();
        }
      });
    }
  };

  loadLiveIdsSuccess() {
    live_stats_loader.load_live_ids_finished = true;
  };

  loadLiveIdsError() {
    live_stats_loader.load_live_ids_finished = true;
  };

}

export let live_stats_loader = new LiveStatsLoader();