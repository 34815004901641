export class MenuBar {

  init(cs_channel_class_ref, stats_util_class_ref, main_class_ref, detailed_class_ref, history_class_ref, insights_class_ref) {
    this.CsChannel = cs_channel_class_ref;
    this.StatsUtil = stats_util_class_ref;
    this.Main = main_class_ref;
    this.Detailed = detailed_class_ref;
    this.History = history_class_ref;
    this.Insights = insights_class_ref;

    $('.' + html_ref.shared.menu_bars_class).click(this.onMenuBarsClick);
    $(html_ref.sidebar.event_list_id).change(this.onEventListChange);
    $(html_ref.sidebar.sidebar_data_source_id).change(this.onDataSourceChange);
    $(html_ref.sidebar.sidebar_fight_advancement_mode_id).change(this.onFightAdvancementModeChange);
    $(html_ref.sidebar.sidebar_id).on('click', '.' + html_ref.sidebar.fight_btn_class, this.onFightBtnClick);
    $('.' + html_ref.shared.header_class).on('click', '.' + html_ref.shared.check_round_class, this.onRoundBtnClick);
    $('.' + html_ref.shared.header_class).on('click', '.view-btn', this.onViewBtnClick);

    if (this.StatsUtil.inStatsView()) {
      MenuBar.loadFight($(html_ref.shared.current_view_id).data('picked-event-id'), $(html_ref.shared.current_view_id).data('picked-fight-id'));
    }
  };

  // Sidebar

  onEventListChange() {    
    MenuBar.loadEventFightList($(this).val(), function(){});
  };

  static loadEventFightList(event_id, callback) {
    $(html_ref.sidebar.fight_list_id).empty();
    $(html_ref.sidebar.fight_list_spinner_id).addClass('spinner');
    $(html_ref.sidebar.fight_list_id).load('/menu_bar/render_event_fights', {event_id: event_id}, function(response, status, xhr) {
      if (status == 'error') {
        toastr.error('Unable to load fight list. Please refresh and try again.');
      } else {
        $(html_ref.sidebar.fight_list_spinner_id).removeClass('spinner');
        callback();
      }
    });
  };

  onDataSourceChange() {
    var source = $(this).val();
    if ($(html_ref.sidebar.sidebar_id).data('user-role') == 'admin') {
      // Save settings
      $.ajax({
        url: '/system_settings/ajax_post_settings',
        method: 'POST',
        data: {
          field: 'settings_data_source',
          value: source
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data) {
          if (data['success']) {
            menu_bar.StatsUtil.clearCurrentView();
            $(html_ref.sidebar.sidebar_id).data('api-source', source).attr('data-api-source', source);
            // Close menu
            if ($('.' + html_ref.shared.menu_bars_class).hasClass('open')) {
              $('.' + html_ref.shared.menu_bars_class).click();
            }
          } else {
            toastr.error('Unable to update data source. Please refresh and try again.');
          }
        },
        error: function(data) {
          toastr.error('Unable to update data source. Please refresh and try again.');
        }
      });
    } else {
      menu_bar.StatsUtil.clearCurrentView();
      $(html_ref.sidebar.sidebar_id).data('api-source', source).attr('data-api-source', source);
      // Close menu
      if ($('.' + html_ref.shared.menu_bars_class).hasClass('open')) {
        $('.' + html_ref.shared.menu_bars_class).click();
      }
    }
  };

  onFightAdvancementModeChange() {
    // Save settings
    if ($(html_ref.sidebar.sidebar_id).data('user-role') == 'admin') {
      $.ajax({
        url: '/system_settings/ajax_post_settings',
        method: 'POST',
        data: {
          field: 'settings_advancement_mode',
          value: $(this).val()
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data) {
          if (!data['success']) {
            toastr.error('Unable to update fight advancement mode. Please refresh and try again.');
          }
        },
        error: function(data) {
          toastr.error('Unable to update fight advancement mode. Please refresh and try again.');
        }
      });
    }
  };

  onFightBtnClick() {
    if (menu_bar.StatsUtil.inStatsView()) {
      MenuBar.loadFight($(this).data('event-id'), $(this).data('fight-id'));

      // Close menu
      if ($('.' + html_ref.shared.menu_bars_class).hasClass('open')) {
        $('.' + html_ref.shared.menu_bars_class).click();
      }

      // Broadcast
      if ($(html_ref.sidebar.sidebar_id).data('user-role') == 'admin') {
        menu_bar.CsChannel.broadcastMessage({type: 'AdminFight-Update', event_id: $(this).data('event-id'), fight_id: $(this).data('fight-id')});
      }
    } else {
      window.location = '/';
    }
  };

  static loadFight(event_id, fight_id) {
    // Load ids
    $(html_ref.shared.current_view_id).data('picked-event-id', event_id).attr('data-picked-event-id', event_id);
    $(html_ref.shared.current_view_id).data('picked-fight-id', fight_id).attr('data-picked-fight-id', fight_id);
    let red_fighter_id = $('.' + html_ref.sidebar.fight_btn_class + '[data-fight-id="' + String(fight_id) + '"]').data('red-fighter-id');
    let blue_fighter_id = $('.' + html_ref.sidebar.fight_btn_class + '[data-fight-id="' + String(fight_id) + '"]').data('blue-fighter-id')
    $(html_ref.shared.current_view_id).data('picked-fight-red-fighter-id', red_fighter_id).attr('data-picked-fight-red-fighter-id', red_fighter_id);
    $(html_ref.shared.current_view_id).data('picked-fight-blue-fighter-id', blue_fighter_id).attr('data-picked-fight-blue-fighter-id', blue_fighter_id);
    let wc_id = $('.' + html_ref.sidebar.fight_btn_class + '[data-fight-id="' + String(fight_id) + '"]').data('wc-id');
    $(html_ref.shared.current_view_id).data('picked-fight-wc-id', wc_id).attr('data-picked-fight-wc-id', wc_id);
    $(html_ref.shared.current_view_id).data('picked-round', '0').attr('data-picked-round', '0');
    
    // Load names
    $(html_ref.sidebar.picked_fight_red_firstname_id).val($('#' + String(fight_id) + '_red_firstname').val());
    $(html_ref.sidebar.picked_fight_red_lastname_id).val($('#' + String(fight_id) + '_red_lastname').val());
    $(html_ref.sidebar.picked_fight_blue_firstname_id).val($('#' + String(fight_id) + '_blue_firstname').val());
    $(html_ref.sidebar.picked_fight_blue_lastname_id).val($('#' + String(fight_id) + '_blue_lastname').val());
    $(html_ref.sidebar.picked_fight_wc_abbr_id).val($('#' + String(fight_id) + '_wc_abbr').val());

    // Reset rounds
    menu_bar.StatsUtil.activateRounds(null, 0, false);

    $('.' + html_ref.shared.round_menu_class + ' .yellow-bg').removeClass('yellow-bg');
    $(html_ref.shared.header_total_id).addClass('yellow-bg');

    if (menu_bar.StatsUtil.isMainViewLoaded()) {
      menu_bar.Main.renderMain();
    } else if (menu_bar.StatsUtil.isDetailedViewLoaded()) {
      menu_bar.Detailed.renderDetailed();
    } else if (menu_bar.StatsUtil.isHistoryViewLoaded()) {
      menu_bar.History.renderHistory();
    } else if (menu_bar.StatsUtil.isInsightsViewLoaded()) {
      menu_bar.Insights.renderInsights();
    }
  };

  // Header

  onMenuBarsClick() {
    $('.' + html_ref.shared.menu_bars_class).toggleClass('open');
    $('.' + html_ref.shared.slider_class).slideToggle(200);
  };

  onRoundBtnClick() {
    if (!$(this).hasClass('darkgray-font')) {
      menu_bar.StatsUtil.clearCurrentView();
      $('.' + html_ref.shared.check_round_class).removeClass('yellow-bg').addClass('hover');
      $(this).addClass('yellow-bg').removeClass('hover');
      $(html_ref.shared.fight_status_holder_parent_id).removeClass('yellow-bg');
      $(html_ref.shared.current_view_id).data('picked-round', $(this).data('round'));
    }
  };

  onViewBtnClick() {
    if (menu_bar.StatsUtil.inStatsView()) {
      if ((this.id == 'main-btn') && !menu_bar.StatsUtil.isMainViewLoaded()) {
        menu_bar.Main.renderMain();
      } else if ((this.id == 'detailed-btn') && !menu_bar.StatsUtil.isDetailedViewLoaded()) {
        menu_bar.Detailed.renderDetailed();
      } else if ((this.id == 'history-btn') && !menu_bar.StatsUtil.isHistoryViewLoaded()) {
        menu_bar.History.renderHistory();
      } else if ((this.id == 'insights-btn') && !menu_bar.StatsUtil.isInsightsViewLoaded()) {
        menu_bar.Insights.renderInsights();
      }
    } else {
      window.location = '/';
    }
  };
}

export let menu_bar = new MenuBar();