export class History {

  init(stats_util_class_ref) {
    this.fight_stats = null;
    this.fight_stat_mappings = null;

    this.StatsUtil = stats_util_class_ref;
  };

  static renderHistory() {
    $(html_ref.shared.current_view_id).data('current-view', 'history');
    $(html_ref.shared.current_view_id).addClass('history-body');
    $(html_ref.shared.body_inner_id).addClass('history-parent');
    $('.' + html_ref.shared.view_btn_class).removeClass('active-font');
    $(html_ref.shared.history_btn_id).addClass('active-font');
    $(html_ref.shared.current_view_id).load('/screens/render_history', {}, function(response, status, xhr) {
      if (status == 'error') {
        toastr.error('Unable to load history view.');
      } else {
        $(html_ref.shared.current_view_id).removeClass('main-body detailed-body insights-body');
        $(html_ref.shared.body_inner_id).removeClass('main-parent detailed-parent insights-parent');
        history.fight_stats = null;
        history.fight_stat_mappings = history.StatsUtil.statMappings('detailed');
        history.StatsUtil.clearHistoryView();
        history.StatsUtil.loadFighterNames();
        history.StatsUtil.loadWCAbbr();
        history.StatsUtil.getFightMatchupStats(true, history.injectMatchupStats);
      }
    });
  };

  static injectFightStats(data) {
    if (history.fight_stat_mappings && history.StatsUtil.isHistoryViewLoaded() && ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(data['FightId'])) && ($(html_ref.shared.current_view_id).data('picked-round') == String(data['Round'])) && ($('#sidebar').data('api-source') == data['Source'])) {      
      // Insert official flag if available
      history.StatsUtil.insertFightStatus(data, false);

      for (const [key, val] of Object.entries(history.fight_stat_mappings)) {
        $('.red-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Red']) + val['unit']);
        $('.blue-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Blue']) + val['unit']);
      }

      // Show
      history.StatsUtil.showHistoryView();

      history.fight_stats = data;
    }
  };

  injectMatchupStats(data) {
    if (history.StatsUtil.isHistoryViewLoaded() && ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(data['FightId']))) {
      for (const [key, val] of Object.entries(history.StatsUtil.statMappings('matchup'))) {
        if (data[val['data'][0]][val['data'][1]]['Red'] !== '') {
          $('.red-matchup-' + val['data'][0].toLowerCase() + '-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Red']) + val['unit']);
        } else {
          $('.red-matchup-' + val['data'][0].toLowerCase() + '-' + String(key)).text('');
        }
        if (data[val['data'][0]][val['data'][1]]['Blue'] !== '') {
          $('.blue-matchup-' + val['data'][0].toLowerCase() + '-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Blue']) + val['unit']);
        } else {
          $('.blue-matchup-' + val['data'][0].toLowerCase() + '-' + String(key)).text('');
        }
        if (data[val['data'][0]][val['data'][1]]['WC'] !== '') {
          $('.wc-matchup-' + val['data'][0].toLowerCase() + '-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['WC']) + val['unit']);
        } else {
          $('.wc-matchup-' + val['data'][0].toLowerCase() + '-' + String(key)).text('');
        }
        if (data[val['data'][0]][val['data'][1]]['UFC'] !== '') {
          $('.ufc-matchup-' + val['data'][0].toLowerCase() + '-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['UFC']) + val['unit']);
        } else {
          $('.ufc-matchup-' + val['data'][0].toLowerCase() + '-' + String(key)).text('');
        }
      }
    }
  };

}

export let history = new History();