// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "jquery-ui"

// Import only the d3 submodules we need for rbr bar charts and assign them to global d3 object for access
import { select, selectAll } from "d3-selection"
import { scaleLinear } from "d3-scale"
import { min, max } from "d3-array"

window.d3 = Object.assign(
  {},
  {
    select,
    selectAll,
    scaleLinear,
    min,
    max
  }
)

// Toastr
import toastr from "toastr"
window.toastr = toastr;
window.toastr.options = {
  "positionClass": "toast-bottom-right"
};

Rails.start()
ActiveStorage.start()

// Custom JS
import html_ref from '../const/html_ref'
window.html_ref = html_ref;

$(() => {if ($(".logged-in").length) { require("shared") }});
$(() => {if ($(".logged-in").length) { require("screens") }});
$(() => {if (!$(".logged-in").length) { require("devise") }});

